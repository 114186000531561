import React from "react"
import LayoutCourse from "../../../../../layouts/course"
import Seo from "../../../../../components/seo"
import Heading from "../../../../../components/heading"
import Stack from "../../../../../components/stack"
import CourseFooterNext from "../../../../../components/course-footer-next"
import Text from "../../../../../components/text"
import {
  getCourseNavigation,
  useCourseStore,
  getCourseProgress,
} from "../../../../../store/courses"
import TaskSidebar from "../../../../../components/task-sidebar"
import Note from "../../../../../components/note"
import NotationGroup from "../../../../../components/notation-group"
import Notation from "../../../../../components/notation"
import Abbreviation from "../../../../../components/abbreviation"
import Paragraph from "../../../../../components/paragraph"
import PaperStack from "../../../../../components/paper-stack"

const taskProps = {
  courseId: "hoelderlins-reisen",
  chapterId: "01-friedrich-hoelderlin",
  taskId: "reisetagebuch",
}

const Page = () => {
  const { setAnswer } = useCourseStore()
  const navigation = getCourseNavigation(taskProps)
  const progress = getCourseProgress(taskProps)

  return (
    <LayoutCourse
      footer={
        <CourseFooterNext to="/kurse/hoelderlins-reisen/01-friedrich-hoelderlin/reisetagebuch/reiseroute" />
      }
      navigation={navigation}
      progress={progress}
    >
      <Seo title="Orte markieren" />
      <Stack>
        <Heading as="h2" level={2}>
          Orte markieren
        </Heading>
        <Paragraph>
          Hier findest du deinen Auszug aus Hölderlins Reisetagebuch. Er erzählt
          seiner Mutter, an welchen Orten er war. Kannst du die Ortsnamen im
          Text finden? Besonders schwierige Wörter haben wir unterstrichen. Du
          kannst sie anklicken und erhältst eine kurze Erklärung.
        </Paragraph>
        <TaskSidebar
          main={
            <NotationGroup
              onNotationsUpdated={(notations) => {
                setAnswer({
                  ...taskProps,
                  taskId: "reisetagebuch-ortsnamen",
                  answer: notations,
                })
              }}
            >
              <PaperStack>
                <Text as="div" size={[2, 3]}>
                  <Stack space={6}>
                    <p>Liebste Mamma! </p>
                    <p>
                      Hier ein Stük meines Reistagebuchs. Sie müssen eben{" "}
                      <Abbreviation title="sich mit etwas zufrieden geben">
                        vorlieb nehmen
                      </Abbreviation>{" "}
                      mit dem{" "}
                      <Abbreviation title="Geschmier">Gesudel</Abbreviation>,
                      ich schriebs oft halb im Schlaf, eh ich zu Bette gieng.
                      Ich denke noch immer mit Vergnügen an die, obschon kurze
                      fünftägige, doch weite Reise. Ich reiste von{" "}
                      <Notation id="q-01">Mannheim</Notation> aus noch weiter
                      nach <Notation id="q-02">Frankenthal</Notation> – wie Sie
                      nächstens hören werden. Also tausend Dank, liebste Mamma,
                      für das mir gemachte Vergnügen. Ich habe Ihnen
                      versprochen, alles aufzuschreiben – hier ist es.
                    </p>

                    <Stack space={3}>
                      <p>
                        In <Notation id="q-03">Bruchsaal</Notation>{" "}
                        <Abbreviation title="Gasthaus-Rechnung">
                          Zeche
                        </Abbreviation>{" "}
                        43 cr.{" "}
                      </p>
                      <p>Fahrlohn über den Rhein 8 cr.</p>
                      <p>
                        Zu <Notation id="q-04">Rheinhausen</Notation>{" "}
                        <Abbreviation title="Gasthaus-Rechnung">
                          Zeche
                        </Abbreviation>{" "}
                        7 cr.
                      </p>
                      <p>Wieder Fahrlohn über den Rhein 24 cr.</p>
                      <p>
                        In der <Notation id="q-05">Mannheimer</Notation>{" "}
                        <Abbreviation title="Theater">Comedie</Abbreviation> 48
                        cr.
                      </p>
                      <p>
                        Dem <Notation id="q-06">Mannheimer</Notation>{" "}
                        <Abbreviation title="Perückenmacher">
                          Peruqieu
                        </Abbreviation>{" "}
                        24 cr.
                      </p>
                      <p>
                        Zu <Notation id="q-08">Frankenthal</Notation> zahlt ich
                        die{" "}
                        <Abbreviation title="Gasthaus-Rechnung">
                          Zeche
                        </Abbreviation>{" "}
                        1 f. 58 cr.
                      </p>
                      <p>
                        Zu <Notation id="q-09">Speier</Notation> Trinkgeld 36
                        cr.{" "}
                      </p>
                      <p>
                        Dem <Notation id="q-10">Speirer</Notation>{" "}
                        <Abbreviation title="Perückenmacher">
                          Peruqieu
                        </Abbreviation>{" "}
                        24 cr.{" "}
                      </p>
                      <p>
                        Von <Notation id="q-11">Speier</Notation> zurük nahm ich
                        ein Pferd 1 f. 50 cr.{" "}
                      </p>
                      <p>
                        In <Notation id="q-12">Bruchsaal</Notation> für den Mann{" "}
                        <Abbreviation title="Gasthaus-Rechnung">
                          Zeche
                        </Abbreviation>{" "}
                        15 cr.
                      </p>
                      <p>Für das Pferd im Hinabreisen 2 f.</p>
                      <p>Mit Kleinigkeiten 1 f. </p>
                    </Stack>
                    <p>
                      Blum zahlte auf der Reise die meiste{" "}
                      <Abbreviation title="Gasthaus-Rechnung">
                        Zeche
                      </Abbreviation>
                      , wie Sie sehen werden – ich kam also herrlich davon.
                      Sagen Sie dem lieben{" "}
                      <Abbreviation title="Hölderlins Bruder">
                        Carl
                      </Abbreviation>
                      , in der Fortsezung komme viel vor von großen Schiffen,
                      mit Seegeln und Mastbäumen. Er soll sich nur recht freuen.
                      Denken Sie, liebste Mamma, ich war nicht ganz wohl, eh'
                      ich abreiste, nahm noch den Abend vorher Arznei zu mir –
                      habe mich aber so gesund gereist, daß mirs jederman
                      ansieht.
                    </p>
                  </Stack>
                </Text>
              </PaperStack>
            </NotationGroup>
          }
          sidebar={
            <Note variant="task">
              Markiere alle Ortsnamen, indem du sie anklickst.
            </Note>
          }
        />
      </Stack>
    </LayoutCourse>
  )
}

export default Page
